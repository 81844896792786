<template>
    <div>
        <div class="row">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                        >{{ $t("customer.nameKh") }}</label
                                    >
                                    <div class="col-md-8">
                                        <input
                                            v-model.trim="
                                                model.customer_name_kh
                                            "
                                            ref="customer_name_kh"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'customer_name_kh'
                                                )
                                            }"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('customer_name_kh')
                                            "
                                        >
                                            {{
                                                errors.first("customer_name_kh")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4 required"
                                        >{{ $t("customer.nameEn") }}</label
                                    >
                                    <div class="col-md-8">
                                        <input
                                            v-model.trim="
                                                model.customer_name_en
                                            "
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    errors.has(
                                                        'customer_name_en'
                                                    ) ||
                                                    $v.model.customer_name_en
                                                        .$error
                                            }"
                                        />
                                        <span
                                            class="invalid-feedback"
                                            v-if="
                                                customerNameEnErrors.length > 0
                                            "
                                            >{{ customerNameEnErrors[0] }}</span
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('customer_name_en')
                                            "
                                        >
                                            {{
                                                errors.first("customer_name_en")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4 required"
                                    >
                                        {{ $t("customer.sex") }}
                                    </label>
                                    <div class="col-md-8">
                                        <div class="form-control-plaintext">
                                            <div
                                                class="form-check form-check-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    id="male"
                                                    v-model="model.gender"
                                                    value="Male"
                                                    @input="
                                                        $v.model.gender.$touch()
                                                    "
                                                    :class="{
                                                        'is-invalid':
                                                            errors.has(
                                                                'gender'
                                                            ) ||
                                                            $v.model.gender
                                                                .$error
                                                    }"
                                                />
                                                <label
                                                    for="male"
                                                    class="form-check-label"
                                                    >Male</label
                                                >
                                            </div>
                                            <div
                                                class="form-check form-check-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    id="female"
                                                    v-model="model.gender"
                                                    value="Female"
                                                    @input="
                                                        $v.model.gender.$touch()
                                                    "
                                                    :class="{
                                                        'is-invalid':
                                                            errors.has(
                                                                'gender'
                                                            ) ||
                                                            $v.model.gender
                                                                .$error
                                                    }"
                                                />
                                                <label
                                                    for="female"
                                                    class="form-check-label"
                                                    >Female</label
                                                >
                                            </div>
                                            <br />
                                            <span
                                                class="text-danger"
                                                v-if="genderErrors.length > 0"
                                                >{{ genderErrors[0] }}</span
                                            >
                                            <div
                                                class="invalid-feedback"
                                                v-if="errors.has('gender')"
                                            >
                                                {{ errors.first("gender") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4 required"
                                        >{{ $t("customer.dateOfBirth") }}</label
                                    >
                                    <div class="col-md-8">
                                        <DatePicker
                                            type="date"
                                            :value="model.date_of_birth"
                                            style="width:100%"
                                            placeholder="DD-MM-YYYY"
                                            format="dd-MM-yyyy"
                                            @on-change="onChangeDateOfBirth"
                                            :class="{
                                                'is-invalid':
                                                    errors.has(
                                                        'date_of_birth'
                                                    ) ||
                                                    $v.model.date_of_birth
                                                        .$error
                                            }"
                                        ></DatePicker>
                                        <span
                                            class="text-danger"
                                            v-if="dateOfBirthErrors.length > 0"
                                            >{{ dateOfBirthErrors[0] }}</span
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('date_of_birth')"
                                        >
                                            {{ errors.first("date_of_birth") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.marritalStatus") }}
                                    </label>
                                    <div class="col-md-8">
                                        <select
                                            v-model.number="
                                                model.marrital_status
                                            "
                                            class="form-select"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'marrital_status'
                                                )
                                            }"
                                        >
                                            <option :value="null">{{
                                                $t("select")
                                            }}</option>
                                            <option
                                                v-for="(value,
                                                index) in marritalStatus"
                                                :key="index"
                                                :value="value"
                                            >
                                                {{ value }}
                                            </option>
                                        </select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('marrital_status')"
                                        >
                                            {{
                                                errors.first("marrital_status")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.occupation") }}
                                    </label>
                                    <div class="col-md-8">
                                        <Select
                                            v-model="model.occupation"
                                            :class="{
                                                'ivu-form-item-error': errors.has(
                                                    'occupation'
                                                )
                                            }"
                                            filterable
                                            clearable
                                        >
                                            <OptionGroup
                                                v-for="(sector,
                                                index) in occupations"
                                                :label="
                                                    sector.sector_code +
                                                        '|' +
                                                        (locale == 'kh'
                                                            ? sector.sector_kh
                                                            : sector.sector_en)
                                                "
                                                :key="index"
                                            >
                                                <Option
                                                    v-for="(option,
                                                    i) in sector.occupations"
                                                    :value="option.id"
                                                    :key="i"
                                                    >{{
                                                        option.occupation_code +
                                                            "-" +
                                                            (locale == "kh"
                                                                ? option.occupation_kh
                                                                : option.occupation_en)
                                                    }}</Option>
                                            </OptionGroup>
                                        </Select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('occupation')"
                                        >
                                            {{ errors.first("occupation") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4 required"
                                        >{{ $t("customer.contactNo") }}</label
                                    >
                                    <div class="col-md-8">
                                        <input
                                            v-model.trim="model.contact_no"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    errors.has('contact_no') ||
                                                    $v.model.contact_no.$error
                                            }"
                                        />
                                        <span
                                            class="text-danger"
                                            v-if="contactNoErrors.length > 0"
                                            >{{ contactNoErrors[0] }}</span
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('contact_no')"
                                        >
                                            {{ errors.first("contact_no") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                        >{{ $t("customer.email") }}</label
                                    >
                                    <div class="col-md-8">
                                        <input
                                            v-model.trim="model.email"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'email'
                                                )
                                            }"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('email')"
                                        >
                                            {{ errors.first("email") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.province") }}
                                    </label>
                                    <div class="col-md-8">
                                        <select
                                            v-model="model.province_code"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'province_code'
                                                )
                                            }"
                                            @change="
                                                provinceChange(
                                                    model.province_code
                                                )
                                            "
                                            @input="
                                                model.district_code = model.commune_code = model.village_code = null
                                            "
                                        >
                                            <option
                                                :value="null"
                                                disabled
                                                selected
                                                style="display: none;"
                                                >{{ $t("select") }}</option
                                            >
                                            <option
                                                v-for="(province,
                                                index) in provinces"
                                                :key="index"
                                                :value="province.province_code"
                                            >
                                                {{ province.name_en }} |
                                                {{ province.name_kh }}
                                            </option>
                                        </select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('province_code')"
                                        >
                                            {{ errors.first("province_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.district") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="province_change"
                                        >
                                            <select
                                                v-model="model.district_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'district_code'
                                                    )
                                                }"
                                                @change="
                                                    districtChange(
                                                        model.district_code
                                                    )
                                                "
                                                @input="
                                                    model.commune_code = model.village_code = null
                                                "
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(district,
                                                    index) in districts"
                                                    :key="index"
                                                    :value="
                                                        district.district_code
                                                    "
                                                >
                                                    {{ district.name_en }} |
                                                    {{ district.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('district_code')"
                                        >
                                            {{ errors.first("district_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.commune") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="district_change"
                                        >
                                            <select
                                                v-model="model.commune_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'commune_code'
                                                    )
                                                }"
                                                @change="
                                                    communeChange(
                                                        model.commune_code
                                                    )
                                                "
                                                @input="
                                                    model.village_code = null
                                                "
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(commune,
                                                    index) in communes"
                                                    :key="index"
                                                    :value="
                                                        commune.commune_code
                                                    "
                                                >
                                                    {{ commune.name_en }} |
                                                    {{ commune.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('commune_code')"
                                        >
                                            {{ errors.first("commune_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.village") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="commune_change"
                                        >
                                            <div class="input-group">
                                                <select
                                                    v-model="model.village_code"
                                                    class="form-control"
                                                    :class="{
                                                        'is-invalid': errors.has(
                                                            'village_code'
                                                        )
                                                    }"
                                                >
                                                    <option
                                                        :value="null"
                                                        disabled
                                                        selected
                                                        style="display: none;"
                                                        >{{
                                                            $t("select")
                                                        }}</option>
                                                    <option
                                                        v-for="(village,
                                                        index) in villages"
                                                        :key="index"
                                                        :value="
                                                            village.village_code
                                                        "
                                                    >
                                                        {{ village.name_en }} |
                                                        {{ village.name_kh }}
                                                    </option>
                                                </select>
                                                <Poptip
                                                    :title="
                                                        $t('customer.village')
                                                    "
                                                    :transfer="true"
                                                    width="240"
                                                    @on-popper-show="
                                                        () =>
                                                            (name_en = name_kh =
                                                                '')
                                                    "
                                                    v-if="
                                                        !villageSaving &&
                                                            model.commune_code
                                                    "
                                                >
                                                    <a
                                                        class="btn btn-info"
                                                        v-tooltip="$t('add')"
                                                        :disabled="
                                                            villageSaving
                                                        "
                                                    >
                                                        <i
                                                            class="fas fa-circle-notch fa-spin"
                                                            v-if="villageSaving"
                                                        ></i>
                                                        <i
                                                            class="fas fa-plus"
                                                            v-else
                                                        ></i>
                                                    </a>
                                                    <div slot="content">
                                                        <div class="mb-2">
                                                            <label
                                                                class="required"
                                                                >{{
                                                                    $t(
                                                                        "customer.nameEn"
                                                                    )
                                                                }}</label
                                                            >
                                                            <input
                                                                v-model.trim="
                                                                    name_en
                                                                "
                                                                type="text"
                                                                class="form-control"
                                                                :class="{
                                                                    'is-invalid':
                                                                        errors.has(
                                                                            'name_en'
                                                                        ) ||
                                                                        $v
                                                                            .name_en
                                                                            .$error
                                                                }"
                                                            />
                                                            <span
                                                                class="invalid-feedback"
                                                                v-if="
                                                                    nameEnErrors.length >
                                                                        0
                                                                "
                                                                >{{
                                                                    nameEnErrors[0]
                                                                }}</span
                                                            >
                                                            <div
                                                                class="invalid-feedback"
                                                                v-if="
                                                                    errors.has(
                                                                        'name_en'
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    errors.first(
                                                                        "name_en"
                                                                    )
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="mb-2">
                                                            <label
                                                                class="required"
                                                                >{{
                                                                    $t(
                                                                        "customer.nameKh"
                                                                    )
                                                                }}</label
                                                            >
                                                            <input
                                                                v-model.trim="
                                                                    name_kh
                                                                "
                                                                type="text"
                                                                class="form-control"
                                                                :class="{
                                                                    'is-invalid':
                                                                        errors.has(
                                                                            'name_kh'
                                                                        ) ||
                                                                        $v
                                                                            .name_kh
                                                                            .$error
                                                                }"
                                                            />
                                                            <span
                                                                class="invalid-feedback"
                                                                v-if="
                                                                    nameEnErrors.length >
                                                                        0
                                                                "
                                                                >{{
                                                                    nameEnErrors[0]
                                                                }}</span
                                                            >
                                                            <div
                                                                class="invalid-feedback"
                                                                v-if="
                                                                    errors.has(
                                                                        'name_kh'
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    errors.first(
                                                                        "name_kh"
                                                                    )
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="tw-mt-2 tw-text-right"
                                                        >
                                                            <a
                                                                class="ivu-btn ivu-btn-primary ivu-btn-small"
                                                                @click.prevent="
                                                                    onSaveVillage
                                                                "
                                                            >
                                                                <span
                                                                    >Save</span
                                                                >
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Poptip>
                                                <a
                                                    class="btn btn-default"
                                                    v-tooltip="$t('add')"
                                                    :disabled="
                                                        !model.commune_code
                                                    "
                                                    v-else
                                                >
                                                    <i
                                                        class="fas fa-circle-notch fa-spin"
                                                        v-if="villageSaving"
                                                    ></i>
                                                    <i
                                                        class="fas fa-plus"
                                                        v-else
                                                    ></i>
                                                </a>
                                            </div>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('village_code')"
                                        >
                                            {{ errors.first("village_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.street") }}
                                    </label>
                                    <div class="col-md-8">
                                        <input
                                            type="text"
                                            v-model="model.street_no"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'street_no'
                                                )
                                            }"
                                        />
                                        <div
                                            v-if="errors.has('street_no')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("street_no") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.house") }}
                                    </label>
                                    <div class="col-md-8">
                                        <input
                                            type="text"
                                            v-model="model.house_no"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'house_no'
                                                )
                                            }"
                                        />
                                        <div
                                            v-if="errors.has('house_no')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("house_no") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.description") }}
                                    </label>
                                    <div class="col-md-8">
                                        <textarea
                                            type="text"
                                            v-model="model.description"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'description'
                                                )
                                            }"
                                            rows="3"
                                        ></textarea>
                                        <div
                                            v-if="errors.has('description')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("description") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <fieldset>
                            <legend class="text-blue">
                                {{ $t("customer.ssn") }}
                            </legend>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{ $t("customer.documentType") }}</label
                                >
                                <div class="col-md-7">
                                    <select
                                        v-model.number="model.doc_type_id"
                                        class="form-select"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'doc_type_id'
                                            )
                                        }"
                                    >
                                        <option :value="null" selected>{{
                                            $t("select")
                                        }}</option>
                                        <option
                                            v-for="(doc_type,
                                            index) in documentTypes"
                                            :key="index"
                                            :value="doc_type.doc_type_id"
                                        >
                                            {{ doc_type.doc_type_en }} |
                                            {{ doc_type.doc_type_kh }}
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('doc_type_id')"
                                    >
                                        {{ errors.first("doc_type_id") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{ $t("customer.nidOrpassport") }}</label
                                >
                                <div class="col-md-7">
                                    <input
                                        v-model.trim="model.ssn_no"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('ssn_no')
                                        }"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('ssn_no')"
                                    >
                                        {{ errors.first("ssn_no") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t("customer.nidOrpassportIssuedDate")
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <DatePicker
                                        type="date"
                                        :value="model.issued_date"
                                        style="width:100%"
                                        placeholder="DD-MM-YYYY"
                                        format="dd-MM-yyyy"
                                        @on-change="onChangeIssuedDate"
                                    ></DatePicker>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('issued_date')"
                                    >
                                        {{ errors.first("issued_date") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t("customer.nidOrpassportExpiryDate")
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <DatePicker
                                        type="date"
                                        :value="model.expiry_date"
                                        style="width:100%"
                                        placeholder="DD-MM-YYYY"
                                        format="dd-MM-yyyy"
                                        @on-change="onChangeExpiryDate"
                                    ></DatePicker>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('expiry_date')"
                                    >
                                        {{ errors.first("expiry_date") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t("customer.nidOrpassportIssuedAt")
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <textarea
                                        v-model.trim="model.issue_at"
                                        rows="7"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('issue_at')
                                        }"
                                    ></textarea>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('issue_at')"
                                    >
                                        {{ errors.first("issue_at") }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="d-flex flex-column align-items-center">
                    <ts-image-input
                        class="image-input"
                        :file="file"
                        @input="file => (model.photo = file)"
                        :width="175"
                        :height="225"
                        crop
                    ></ts-image-input>
                    <ts-button @click="() => (showForm = true)" class="tw-mt-2">
                        <i class="fas fa-camera mr-2"></i>
                        {{ $t("customer.takePhoto") }}
                    </ts-button>
                    <div style="color:#cc4946;margin:4px 0 0">
                        {{ errors.first("photo") }}
                    </div>
                </div>
            </div>
        </div>
        <!-- webcam -->
        <div v-if="showForm">
            <Modal
                :title="$t('customer.takePhoto')"
                v-model="showForm"
                draggable
                sticky
                scrollable
                :footer-hide="true"
                width="690"
            >
                <web-cam @close="getPhoto" />
            </Modal>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import WebCam from "./web-cam";
import { required } from "vuelidate/lib/validators";
import { isEmpty } from "lodash";

export default {
    name: "profileInformation",
    props: ["value", "validate", "clear"],
    components: {
        WebCam
    },
    data() {
        return {
            model: {},
            loadingPreData: false,
            showForm: false,
            errors: new Errors(),
            file: require("../../../../assets/staff-default-man.png"),
            marritalStatus: [
                "Single|នៅលីវ",
                "Married|រៀបការ",
                "Widow|មេម៉ាយ/ពោះម៉ាយ"
            ],
            province_change: false,
            district_change: false,
            commune_change: false,
            districts: [],
            communes: [],
            villages: [],
            villageSaving: false,
            name_en: null,
            name_kh: null
        };
    },
    validations: {
        model: {
            customer_name_en: { required },
            date_of_birth: { required },
            contact_no: { required },
            gender: { required }
        },
        name_en: { required },
        name_kh: { required },
        form: [
            "model.customer_name_en",
            "model.date_of_birth",
            "model.contact_no",
            "model.gender"
        ],
        villageForm: ["name_en", "name_kh"]
    },
    computed: {
        ...mapState("creditOperation/customer", [
            "photoBlob",
            "edit_data",
            "documentTypes",
            "occupations",
            "provinces"
        ]),
        dateOfBirthErrors() {
            const errors = [];
            if (!this.$v.model.date_of_birth.$error) return errors;
            !this.$v.model.date_of_birth.required && errors.push("Required");
            return errors;
        },
        contactNoErrors() {
            const errors = [];
            if (!this.$v.model.contact_no.$error) return errors;
            !this.$v.model.contact_no.required && errors.push("Required");
            return errors;
        },
        customerNameEnErrors() {
            const errors = [];
            if (!this.$v.model.customer_name_en.$error) return errors;
            !this.$v.model.customer_name_en.required && errors.push("Required");
            return errors;
        },
        genderErrors() {
            const errors = [];
            if (!this.$v.model.gender.$error) return errors;
            !this.$v.model.gender.required && errors.push("Required");
            return errors;
        },
        nameEnErrors() {
            const errors = [];
            if (!this.$v.name_en.$error) return errors;
            !this.$v.name_en.required && errors.push("Required");
            return errors;
        },
        nameKhErrors() {
            const errors = [];
            if (!this.$v.name_kh.$error) return errors;
            !this.$v.name_kh.required && errors.push("Required");
            return errors;
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        ...mapActions("creditOperation/customer", [
            "getProvince",
            "getDistrict",
            "getCommune",
            "getVillage"
        ]),
        async getDocumentType() {
            this.loadingPreData = true;
            await this.$store.dispatch(
                "creditOperation/customer/getOccupation"
            );
            await this.$store
                .dispatch("creditOperation/customer/getDocumentType")
                .then(() => {
                    this.model = this.value;
                    this.setEditData();
                    this.loadingPreData = false;
                })
                .catch(error => {
                    this.loadingPreData = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        validateForm() {
            this.$v.form.$touch();
            var isValid = !this.$v.form.$invalid;
            return isValid;
        },
        async getReligion() {
            this.religionOptions = null;
            let response = await this.fetchReligion();
            this.religionOptions = response.data.map(el => ({
                id: el.id,
                label: el.religion
            }));
        },
        getPhoto() {
            this.file = this.readBlob(this.photoBlob);
            this.model.photo = new File([this.photoBlob], "photo", {
                type: "image/jpeg"
            });
            this.showForm = false;
            this.$store.commit("creditOperation/customer/SET_PHOTO_BLOB", "");
        },
        readBlob(blob) {
            return window.URL.createObjectURL(
                new Blob([blob], { type: "image/jpeg" })
            );
        },
        onChangeDateOfBirth(date) {
            this.model.date_of_birth = date;
        },
        onChangeIssuedDate(date) {
            this.model.issued_date = date;
        },
        onChangeExpiryDate(date) {
            this.model.expiry_date = date;
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.photo = this.edit_data.photo;
                this.file = this.model.photo;
                if (this.edit_data.province_code) {
                    this.provinceChange(this.edit_data.province_code);
                }
                if (this.edit_data.district_code) {
                    this.districtChange(this.edit_data.district_code);
                }
                if (this.edit_data.commune_code) {
                    this.communeChange(this.edit_data.commune_code);
                }
                this.model.province_code = this.edit_data.province_code;
                this.model.district_code = this.edit_data.district_code;
                this.model.commune_code = this.edit_data.commune_code;
                this.model.village_code = this.edit_data.village_code;
                this.model.street_no = this.edit_data.street_no;
                this.model.house_no = this.edit_data.house_no;
                this.model.description = this.edit_data.description;
            }
        },
        async getProvince() {
            this.loadingPreData = true;
            await this.$store
                .dispatch("creditOperation/customer/getProvince")
                .then(() => {
                    this.model = this.value;
                    this.setEditData();
                    this.loadingPreData = false;
                })
                .catch(error => {
                    this.loadingPreData = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        provinceChange(province_code) {
            this.province_change = true;
            this.getDistrict(province_code)
                .then(response => {
                    this.districts = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.province_change = false;
                });
        },

        districtChange(district_code) {
            this.district_change = true;
            this.getCommune(district_code)
                .then(response => {
                    this.communes = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.district_change = false;
                });
        },

        communeChange(commune_code) {
            this.commune_change = true;
            this.getVillage(commune_code)
                .then(response => {
                    this.villages = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.commune_change = false;
                });
        },
        async onSaveVillage() {
            this.$v.villageForm.$touch();
            if (this.$v.villageForm.$invalid) return;

            this.villageSaving = true;
            this.$store
                .dispatch("creditOperation/customer/addVillage", {
                    name_en: this.name_en,
                    name_kh: this.name_kh,
                    commune_code: this.model.commune_code
                })
                .then(response => {
                    this.villages.push({
                        name_en: response.resource.name_en,
                        name_kh: response.resource.name_kh,
                        village_code: response.resource.village_code
                    });
                    this.model.village_code = response.resource.village_code;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.villageSaving = false;
                    this.name_en = "";
                    this.name_kh = "";
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER PROFILE",
                desc: not.text
            });
        }
    },
    watch: {
        validate: {
            handler() {
                this.errors = new Errors(this.validate.errors);
            },
            deep: true
        },
        clear: function(value) {
            if (value == true) {
                this.model = this.value;
                this.file = "";
            }
        },
        "model.province_code": function(value) {
            if (value) {
                this.getDistrict(value);
            }
        },
        "model.district_code": function(value) {
            if (value) {
                this.getCommune(value);
            }
        },
        "model.commune_code": function(value) {
            if (value) {
                this.getVillage(value);
            }
        }
    }
};
</script>
