var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customer.nameKh")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                            _vm.model.spouse_name_kh
                                        ),expression:"\n                                            model.spouse_name_kh\n                                        ",modifiers:{"trim":true}}],ref:"spouse_name_kh",staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'spouse_name_kh'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(
                                            _vm.model.spouse_name_kh
                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "spouse_name_kh", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                            _vm.errors.has('spouse_name_kh')
                                        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("spouse_name_kh"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customer.nameEn")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                            _vm.model.spouse_name_en
                                        ),expression:"\n                                            model.spouse_name_en\n                                        ",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.has(
                                                    'spouse_name_en'
                                                )
                                        },attrs:{"type":"text"},domProps:{"value":(
                                            _vm.model.spouse_name_en
                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "spouse_name_en", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                            _vm.errors.has('spouse_name_en')
                                        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("spouse_name_en"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.sex"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-control-plaintext"},[_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_gender),expression:"model.sp_gender"}],staticClass:"form-check-input",class:{
                                                    'is-invalid':
                                                        _vm.errors.has(
                                                            'sp_gender'
                                                        )
                                                },attrs:{"type":"radio","id":"male","value":"Male"},domProps:{"checked":_vm._q(_vm.model.sp_gender,"Male")},on:{"change":function($event){return _vm.$set(_vm.model, "sp_gender", "Male")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"male"}},[_vm._v("Male")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_gender),expression:"model.sp_gender"}],staticClass:"form-check-input",class:{
                                                    'is-invalid':
                                                        _vm.errors.has(
                                                            'sp_gender'
                                                        )
                                                },attrs:{"type":"radio","id":"female","value":"Female"},domProps:{"checked":_vm._q(_vm.model.sp_gender,"Female")},on:{"change":function($event){return _vm.$set(_vm.model, "sp_gender", "Female")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"female"}},[_vm._v("Female")])]),_c('br'),(_vm.errors.has('sp_gender'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_gender"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customer.dateOfBirth")))]),_c('div',{staticClass:"col-md-8"},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value":_vm.model.sp_date_of_birth,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onSpChangeDateOfBirth}}),(_vm.errors.has('sp_date_of_birth'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_date_of_birth"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.occupation"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('Select',{class:{
                                            'ivu-form-item-error':
                                                _vm.errors.has('sp_occupation')
                                        },attrs:{"filterable":"","clearable":""},model:{value:(_vm.model.sp_occupation),callback:function ($$v) {_vm.$set(_vm.model, "sp_occupation", $$v)},expression:"model.sp_occupation"}},_vm._l((_vm.occupations),function(sector,index){return _c('OptionGroup',{key:index,attrs:{"label":sector.sector_code +
                                                    '|' +
                                                    (_vm.locale == 'kh'
                                                        ? sector.sector_kh
                                                        : sector.sector_en)}},_vm._l((sector.occupations),function(option,i){return _c('Option',{key:i,attrs:{"value":option.id}},[_vm._v(_vm._s(option.occupation_code + "-" + (_vm.locale == "kh" ? option.occupation_kh : option.occupation_en)))])}),1)}),1),(_vm.errors.has('sp_occupation'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_occupation"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customer.contactNo")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.sp_contact_no),expression:"model.sp_contact_no",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'sp_contact_no'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.sp_contact_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "sp_contact_no", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('sp_contact_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_contact_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customer.relationship")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_relationship),expression:"model.sp_relationship"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.model.sp_relationship)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "sp_relationship", $event.target.value)}}})])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.province"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_province_code),expression:"model.sp_province_code"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'sp_province_code'
                                            )
                                        },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "sp_province_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.spProvinceChange(
                                                _vm.model.sp_province_code
                                            )}],"input":function($event){_vm.model.sp_district_code = _vm.model.sp_commune_code = _vm.model.sp_village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.provinces),function(province,index){return _c('option',{key:index,domProps:{"value":province.province_code}},[_vm._v(" "+_vm._s(province.name_en)+" | "+_vm._s(province.name_kh)+" ")])})],2),(_vm.errors.has('sp_province_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_province_code"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.district"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.province_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_district_code),expression:"model.sp_district_code"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.errors.has(
                                                    'sp_district_code'
                                                )
                                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "sp_district_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.spDistrictChange(
                                                    _vm.model.sp_district_code
                                                )}],"input":function($event){_vm.model.sp_commune_code = _vm.model.sp_village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.spDistricts),function(district,index){return _c('option',{key:index,domProps:{"value":district.district_code}},[_vm._v(" "+_vm._s(district.name_en)+" | "+_vm._s(district.name_kh)+" ")])})],2)]),(_vm.errors.has('sp_district_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_district_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.commune"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.district_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_commune_code),expression:"model.sp_commune_code"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.errors.has(
                                                    'sp_commune_code'
                                                )
                                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "sp_commune_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.spCommuneChange(
                                                    _vm.model.sp_commune_code
                                                )}],"input":function($event){_vm.model.sp_village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.spCommunes),function(commune,index){return _c('option',{key:index,domProps:{"value":commune.commune_code}},[_vm._v(" "+_vm._s(commune.name_en)+" | "+_vm._s(commune.name_kh)+" ")])})],2)]),(_vm.errors.has('sp_commune_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_commune_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.village"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.commune_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_village_code),expression:"model.sp_village_code"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.errors.has(
                                                    'sp_village_code'
                                                )
                                            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "sp_village_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.spVillages),function(village,index){return _c('option',{key:index,domProps:{"value":village.village_code}},[_vm._v(" "+_vm._s(village.name_en)+" | "+_vm._s(village.name_kh)+" ")])})],2)]),(_vm.errors.has('sp_village_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_village_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-4"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.street"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_street),expression:"model.sp_street"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'sp_street'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.sp_street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "sp_street", $event.target.value)}}}),(_vm.errors.has('sp_street'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_street"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-4"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.house"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.sp_house),expression:"model.sp_house"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'sp_house'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.sp_house)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "sp_house", $event.target.value)}}}),(_vm.errors.has('sp_house'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_house"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.country"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.country),expression:"model.country"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'country'
                                            )
                                        },attrs:{"type":"text","rows":"3"},domProps:{"value":(_vm.model.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "country", $event.target.value)}}}),(_vm.errors.has('country'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("country"))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"col-md-4"},[_c('fieldset',[_c('legend',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t("customer.ssn"))+" ")]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.documentType")))]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.sp_doc_type_id),expression:"model.sp_doc_type_id",modifiers:{"number":true}}],staticClass:"form-select",class:{
                                        'is-invalid': _vm.errors.has(
                                            'sp_doc_type_id'
                                        )
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "sp_doc_type_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.documentTypes),function(doc_type,index){return _c('option',{key:index,domProps:{"value":doc_type.doc_type_id}},[_vm._v(" "+_vm._s(doc_type.doc_type_en)+" | "+_vm._s(doc_type.doc_type_kh)+" ")])})],2),(_vm.errors.has('sp_doc_type_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_doc_type_id"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassport")))]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.sp_ssn_no),expression:"model.sp_ssn_no",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has('sp_ssn_no')
                                    },attrs:{"type":"text"},domProps:{"value":(_vm.model.sp_ssn_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "sp_ssn_no", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('sp_ssn_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_ssn_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassportIssuedDate")))]),_c('div',{staticClass:"col-md-7"},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value":_vm.model.sp_issued_date,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onSpChangeIssuedDate}}),(_vm.errors.has('sp_issued_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_issued_date"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassportExpiryDate")))]),_c('div',{staticClass:"col-md-7"},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value":_vm.model.sp_expiry_date,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onSpChangeExpiryDate}}),(_vm.errors.has('sp_expiry_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_expiry_date"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-2"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassportIssuedAt")))]),_c('div',{staticClass:"col-md-7"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.sp_issue_at),expression:"model.sp_issue_at",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has('sp_issue_at')
                                    },attrs:{"rows":"7"},domProps:{"value":(_vm.model.sp_issue_at)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "sp_issue_at", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('sp_issue_at'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("sp_issue_at"))+" ")]):_vm._e()])])])])])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('ts-image-input',{staticClass:"image-input",attrs:{"file":_vm.file,"width":175,"height":225,"crop":""},on:{"input":function (file) { return (_vm.model.sp_photo = file); }}}),_c('ts-button',{staticClass:"tw-mt-2",on:{"click":function () { return (_vm.showForm = true); }}},[_c('i',{staticClass:"fas fa-camera mr-2"}),_vm._v(" "+_vm._s(_vm.$t("customer.takePhoto"))+" ")]),_c('div',{staticStyle:{"color":"#cc4946","margin":"4px 0 0"}},[_vm._v(" "+_vm._s(_vm.errors.first("sp_photo"))+" ")])],1)])]),(_vm.showForm)?_c('div',[_c('Modal',{attrs:{"title":_vm.$t('customer.takePhoto'),"draggable":"","sticky":"","scrollable":"","footer-hide":true,"width":"690"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('web-cam',{on:{"close":_vm.getPhoto}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }