<template>
    <div>
        <div class="demo-spin-article">
            <div class="row">
                <div class="col-md-4">
                    <fieldset>
                        <legend class="text-blue">
                            {{ $t("customer.profile") }}
                        </legend>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">
                                {{ $t("customer.nameKh") }}
                            </label>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.guarantor_name_kh"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'guarantor_name_kh'
                                        )
                                    }"
                                />
                                <div
                                    v-if="errors.has('guarantor_name_kh')"
                                    class="invalid-feedback"
                                >
                                    {{ errors.first("guarantor_name_kh") }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">
                                {{ $t("customer.nameEn") }}
                            </label>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.guarantor_name_en"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'guarantor_name_en'
                                        )
                                    }"
                                />
                                <div
                                    v-if="errors.has('guarantor_name_en')"
                                    class="invalid-feedback"
                                >
                                    {{ errors.first("guarantor_name_en") }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">
                                {{ $t("customer.dateOfBirth") }}
                            </label>
                            <div class="col-md-8">
                                <DatePicker
                                    type="date"
                                    :value="model.grt_date_of_birth"
                                    style="width:100%"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeGrtDateOfBirth"
                                ></DatePicker>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">
                                {{ $t("customer.relationship") }}
                            </label>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.relationship"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has('relationship')
                                    }"
                                />
                                <div
                                    v-if="errors.has('relationship')"
                                    class="invalid-feedback"
                                >
                                    {{ errors.first("relationship") }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label class="form-label col-form-label col-md-4">
                                {{ $t("customer.contactNo") }}
                            </label>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.guarantor_phone"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'guarantor_phone'
                                        )
                                    }"
                                />
                                <div
                                    v-if="errors.has('guarantor_phone')"
                                    class="invalid-feedback"
                                >
                                    {{ errors.first("guarantor_phone") }}
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-4">
                    <fieldset>
                        <legend class="text-blue">
                            {{ $t("customer.address") }}
                        </legend>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.province") }}
                                    </label>
                                    <div class="col-md-8">
                                        <select
                                            v-model="model.grt_province_code"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'grt_province_code'
                                                )
                                            }"
                                            @change="
                                                provinceChange(
                                                    model.grt_province_code
                                                )
                                            "
                                            @input="
                                                model.grt_district_code = model.grt_commune_code = model.grt_village_code = null
                                            "
                                        >
                                            <option
                                                :value="null"
                                                disabled
                                                selected
                                                style="display: none;"
                                                >{{ $t("select") }}</option
                                            >
                                            <option
                                                v-for="(province,
                                                index) in provinces"
                                                :key="index"
                                                :value="province.province_code"
                                            >
                                                {{ province.name_en }} |
                                                {{ province.name_kh }}
                                            </option>
                                        </select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('grt_province_code')
                                            "
                                        >
                                            {{
                                                errors.first(
                                                    "grt_province_code"
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.district") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="province_change"
                                        >
                                            <select
                                                v-model="
                                                    model.grt_district_code
                                                "
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'grt_district_code'
                                                    )
                                                }"
                                                @change="
                                                    districtChange(
                                                        model.grt_district_code
                                                    )
                                                "
                                                @input="
                                                    model.grt_commune_code = model.grt_village_code = null
                                                "
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(district,
                                                    index) in districts"
                                                    :key="index"
                                                    :value="
                                                        district.district_code
                                                    "
                                                >
                                                    {{ district.name_en }} |
                                                    {{ district.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('grt_district_code')
                                            "
                                        >
                                            {{
                                                errors.first(
                                                    "grt_district_code"
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.commune") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="district_change"
                                        >
                                            <select
                                                v-model="model.grt_commune_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'grt_commune_code'
                                                    )
                                                }"
                                                @change="
                                                    communeChange(
                                                        model.grt_commune_code
                                                    )
                                                "
                                                @input="
                                                    model.grt_village_code = null
                                                "
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(commune,
                                                    index) in communes"
                                                    :key="index"
                                                    :value="
                                                        commune.commune_code
                                                    "
                                                >
                                                    {{ commune.name_en }} |
                                                    {{ commune.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('grt_commune_code')
                                            "
                                        >
                                            {{
                                                errors.first("grt_commune_code")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.village") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="commune_change"
                                        >
                                            <select
                                                v-model="model.grt_village_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'grt_village_code'
                                                    )
                                                }"
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(village,
                                                    index) in villages"
                                                    :key="index"
                                                    :value="
                                                        village.village_code
                                                    "
                                                >
                                                    {{ village.name_en }} |
                                                    {{ village.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('grt_village_code')
                                            "
                                        >
                                            {{
                                                errors.first("grt_village_code")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.street") }}
                                    </label>
                                    <div class="col-md-8">
                                        <input
                                            type="text"
                                            v-model="model.grt_street_no"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'grt_street_no'
                                                )
                                            }"
                                        />
                                        <div
                                            v-if="errors.has('grt_street_no')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("grt_street_no") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.house") }}
                                    </label>
                                    <div class="col-md-8">
                                        <input
                                            type="text"
                                            v-model="model.grt_house_no"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'grt_house_no'
                                                )
                                            }"
                                        />
                                        <div
                                            v-if="errors.has('grt_house_no')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("grt_house_no") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-4">
                    <fieldset>
                        <legend class="text-blue">
                            {{ $t("customer.ssn") }}
                        </legend>
                        <div class="row mb-3">
                            <label
                                class="form-label col-form-label col-md-5"
                                >{{ $t("customer.documentType") }}</label
                            >
                            <div class="col-md-7">
                                <select
                                    v-model.number="model.grt_doc_type_id"
                                    class="form-select"
                                >
                                    <option :value="null" selected>{{
                                        $t("select")
                                    }}</option>
                                    <option
                                        v-for="(doc_type,
                                        index) in documentTypes"
                                        :key="index"
                                        :value="doc_type.doc_type_id"
                                    >
                                        {{ doc_type.doc_type_en }} |
                                        {{ doc_type.doc_type_kh }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label
                                class="form-label col-form-label col-md-5"
                                >{{ $t("customer.nidOrpassport") }}</label
                            >
                            <div class="col-md-7">
                                <input
                                    v-model.trim="model.grt_ssn_no"
                                    type="text"
                                    class="form-control"
                                />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label
                                class="form-label col-form-label col-md-5"
                                >{{
                                    $t("customer.nidOrpassportIssuedDate")
                                }}</label
                            >
                            <div class="col-md-7">
                                <DatePicker
                                    type="date"
                                    :value="model.grt_issued_date"
                                    style="width:100%"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeGrtIssuedDate"
                                ></DatePicker>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label
                                class="form-label col-form-label col-md-5"
                                >{{
                                    $t("customer.nidOrpassportExpiryDate")
                                }}</label
                            >
                            <div class="col-md-7">
                                <DatePicker
                                    type="date"
                                    :value="model.grt_expiry_date"
                                    style="width:100%"
                                    placeholder="DD-MM-YYYY"
                                    format="dd-MM-yyyy"
                                    @on-change="onChangeGrtExpiryDate"
                                ></DatePicker>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label
                                class="form-label col-form-label col-md-5"
                                >{{
                                    $t("customer.nidOrpassportIssuedAt")
                                }}</label
                            >
                            <div class="col-md-7">
                                <textarea
                                    v-model.trim="model.grt_issue_at"
                                    rows="7"
                                    class="form-control"
                                ></textarea>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loadingPreData"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import { isEmpty } from "lodash";

export default {
    name: "guanrantor-form",
    props: ["value", "validate", "clear"],
    data() {
        return {
            model: {},
            loadingPreData: false,
            errors: new Errors(),
            province_change: false,
            district_change: false,
            commune_change: false,
            districts: [],
            communes: [],
            villages: []
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("creditOperation/customer", ["provinces", "edit_data",  "documentTypes"])
    },
    methods: {
        ...mapActions("creditOperation/customer", [
            "getDistrict",
            "getCommune",
            "getVillage"
        ]),
        provinceChange(province_code) {
            this.province_change = true;
            this.getDistrict(province_code)
                .then(response => {
                    this.districts = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.province_change = false;
                });
        },

        districtChange(district_code) {
            this.district_change = true;
            this.getCommune(district_code)
                .then(response => {
                    this.communes = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.district_change = false;
                });
        },

        communeChange(commune_code) {
            this.commune_change = true;
            this.getVillage(commune_code)
                .then(response => {
                    this.villages = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.commune_change = false;
                });
        },
        onChangeGrtDateOfBirth(date) {
            this.model.grt_date_of_birth = date;
        },
        onChangeGrtIssuedDate(date) {
            this.model.grt_issued_date = date;
        },
        onChangeGrtExpiryDate(date) {
            this.model.grt_expiry_date = date;
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                if (this.edit_data.grt_province_code) {
                    this.provinceChange(this.edit_data.grt_province_code);
                }
                if (this.edit_data.grt_district_code) {
                    this.districtChange(this.edit_data.grt_district_code);
                }
                if (this.edit_data.grt_commune_code) {
                    this.communeChange(this.edit_data.grt_commune_code);
                }
                this.model.grt_province_code = this.edit_data.grt_province_code;
                this.model.grt_district_code = this.edit_data.grt_district_code;
                this.model.grt_commune_code = this.edit_data.grt_commune_code;
                this.model.grt_village_code = this.edit_data.grt_village_code;
                this.model.grt_street_no = this.edit_data.grt_street_no;
                this.model.grt_doc_type_id = this.edit_data.grt_doc_type_id;
                this.model.grt_ssn_no = this.edit_data.grt_ssn_no;
                this.model.grt_issued_date = this.edit_data.grt_issued_date;
                this.model.grt_expiry_date = this.edit_data.grt_expiry_date;
                this.model.grt_issue_at = this.edit_data.grt_issue_at;
                this.model.grt_house_no = this.edit_data.grt_house_no;
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER PROFILE",
                desc: not.text
            });
        }
    },
    watch: {
        validate: {
            handler() {
                this.errors = new Errors(this.validate.errors);
            },
            deep: true
        },
        "model.grt_province_code": function(value) {
            if (value) {
                this.getDistrict(value);
            }
        },
        "model.grt_district_code": function(value) {
            if (value) {
                this.getCommune(value);
            }
        },
        "model.grt_commune_code": function(value) {
            if (value) {
                this.getVillage(value);
            }
        },
        clear: function(value) {
            if (value == true) {
                this.model = this.value;
            }
        }
    }
};
</script>
