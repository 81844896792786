<template>
    <div>
        <fieldset>
            <legend class="text-blue">
                Motor registration card/កាតគ្រីម៉ូតូ
            </legend>
            <div class="row">
                <div class="col-md-12 tw-h-48">
                    <VueFileAgent
                        ref="vueFileAgent"
                        :theme="'default'"
                        :multiple="true"
                        :deletable="true"
                        :meta="true"
                        :linkable="true"
                        :accept="'image/*,video/*,.pdf,.doc,.docx,.ods'"
                        :maxSize="'10MB'"
                        :maxFiles="14"
                        :helpText="'Choose images or files'"
                        :errorText="{
                            type: 'Invalid file type.',
                            size: 'Files should not exceed 10MB in size'
                        }"
                        @select="filesSelected($event)"
                        @beforedelete="onBeforeDelete($event)"
                        @delete="fileDeleted($event)"
                        v-model="fileRecords"
                    ></VueFileAgent>
                    <button
                        :disabled="!fileRecordsForUpload.length"
                        @click.prevent="uploadFiles()"
                        class="btn btn-primary"
                        type="button"
                    >
                        Upload {{ fileRecordsForUpload.length }} files
                    </button>
                </div>
            </div>
        </fieldset>
    </div>
</template>
<script>
import Vue from "vue";
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";
Vue.use(VueFileAgent);
import { isEmpty, forEach } from "lodash";
import httpClient from "@/http-client";
import { mapActions, mapState } from "vuex";

export default {
    name: "MotorRegistrationCard",
    props: ["value"],
    data: function() {
        return {
            fileRecords: [],
            uploadUrl:
                process.env.VUE_APP_PROXY + "/api/customers/upload-files",
            uploadHeaders: {
                "X-Requested-With": "XMLHttpRequest",
                "X-Test-Header": "vue-file-agent",
                Authorization: "Bearer " + this.$store.state.accessToken
            },
            fileRecordsForUpload: [] // maintain an upload queue
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("creditOperation/customer", ["edit_data"])
    },
    methods: {
        ...mapActions("creditOperation/customer", ["removeFileUploaded"]),
        uploadFiles: function() {
            // Using the default uploader. You may use another uploader instead.
            forEach(this.fileRecordsForUpload, f => {
                let frm = new FormData();
                frm.append("file", f.file);
                frm.append("doc_type_id", 3);
                httpClient
                    .post("/api/customers/upload-files", frm, {
                        onUploadProgress: el => {
                            if (el.lengthComputable) {
                                f.progressing = Math.round(
                                    (el.loaded * 100) / el.total
                                );
                            }
                        }
                    })
                    .then(response => {
                        this.model.attach_file_list.push({
                            attached_file: response.data.attached_file,
                            attached_file_name:
                                response.data.attached_file_name,
                            doc_type_id: 3,
                            mime_type: response.data.mime_type,
                            size: response.data.size,
                            size_text: response.data.size_text
                        });
                    })
                    .catch(error => {
                        this.notice({
                            type: "error",
                            text: error.message
                        });
                    });
            });

            this.fileRecordsForUpload = [];
        },
        deleteUploadedFile: function(fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(
                this.uploadUrl,
                this.uploadHeaders,
                fileRecord
            );
        },
        filesSelected: function(fileRecordsNewlySelected) {
            var validFileRecords = fileRecordsNewlySelected.filter(
                fileRecord => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
                validFileRecords
            );
        },
        onBeforeDelete: function(fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                var k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                if (confirm("Are you sure you want to delete?")) {
                    this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                }
            }
        },
        fileDeleted: function(fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
                if (fileRecord.file) {
                    let item = this.model.attach_file_list.filter(
                        f =>
                            f.attached_file_name == fileRecord.file.name &&
                            f.doc_type_id == 3
                    );
                    if (item.length > 0) {
                        this.removeFileUploaded({
                            file_path: item[0].attached_file
                        });
                    }
                    let file_index = this.model.attach_file_list.indexOf(
                        ...this.model.attach_file_list.filter(
                            f => f.attached_file == item[0].attached_file
                        )
                    );
                    this.model.attach_file_list.splice(file_index, 1);
                } else {
                    let item = this.model.attach_file_list.filter(
                        f =>
                            f.size == fileRecord.size &&
                            f.doc_type_id == 3
                    );
                    if (item.length > 0) {
                        this.removeFileUploaded({
                            file_path: item[0].attached_file
                        });
                    }
                    let file_index = this.model.attach_file_list.indexOf(
                        ...this.model.attach_file_list.filter(
                            f => f.attached_file == item[0].attached_file
                        )
                    );
                    this.model.attach_file_list.splice(file_index, 1);
                }
            }
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.edit_data.customer_attachments
                    .filter(att => att.doc_type_id == 3)
                    .map(el =>
                        this.model.attach_file_list.push({
                            attachment_id: el.attachment_id,
                            attached_file: el.attached_file,
                            attached_file_name: el.attached_file_name,
                            doc_type_id: el.doc_type_id,
                            mime_type: el.mime_type,
                            size: el.size,
                            size_text: el.size_text
                        })
                    );

                this.edit_data.customer_attachments
                    .filter(att => att.doc_type_id == 3)
                    .map(el =>
                        this.fileRecords.push({
                            name: el.attached_file_name,
                            type: el.mime_type,
                            size: el.size,
                            size_text: el.size_text,
                            ext: el.attached_file.split(".").pop(),
                            url: el.attached_file
                        })
                    );
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER PROFILE",
                desc: not.text
            });
        }
    }
};
</script>
