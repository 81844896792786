<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="row">
                    <div class="col-md-6">
                        <web-cam
                            ref="webcam"
                            :device-id="deviceId"
                            @started="onStarted"
                            @stopped="onStopped"
                            @error="onError"
                            @notsupported="notSupport"
                            @cameras="onCameras"
                            @camera-change="onCameraChange"
                        />
                        <div class="mt-2">
                            <select v-model="camera" class="form-control">
                                <option :value="null"
                                    >{{ $t("customer.selectDevice") }}
                                </option>
                                <option
                                    v-for="device in devices"
                                    :key="device.deviceId"
                                    :value="device.deviceId"
                                    >{{ device.label }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <vue-croppie
                            ref="croppieRef"
                            :enableOrientation="true"
                            :boundary="{ width: '100%', height: 270 }"
                            :viewport="{
                                width: 175,
                                height: 225,
                                type: 'square'
                            }"
                            @result="result"
                            @update="update"
                        ></vue-croppie>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 tw-space-x-2">
                        <ts-button
                            v-tooltip="'Stop camera'"
                            v-if="startCam"
                            color="danger"
                            @click="onStop"
                        >
                            <i class="fas fa-stop"></i>
                        </ts-button>
                        <ts-button
                            v-if="!startCam"
                            v-tooltip="'Start up'"
                            color="info"
                            @click="onStart"
                            class="ml-2"
                        >
                            <i class="fas fa-play"></i>
                        </ts-button>
                        <ts-button
                            v-if="deviceId"
                            v-tooltip="'Take picture'"
                            color="primary"
                            @click="onCapture"
                            class="ml-2"
                        >
                            <i class="fas fa-camera"></i>
                        </ts-button>
                    </div>
                    <div class="col-md-6 tw-space-x-2" v-if="img !== null">
                        <ts-button @click="rotate(-90)"
                            ><i class="fas fa-undo"></i
                        ></ts-button>
                        <ts-button @click="rotate(90)" class="ml-2"
                            ><i class="fas fa-redo"></i
                        ></ts-button>
                        <ts-button @click="crop()" class="ml-2" color="primary"
                            ><i class="fas fa-crop"></i
                        ></ts-button>
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";
import { mapMutations } from "vuex";
export default {
    name: "webCamComponent",
    components: {
        WebCam
    },
    data() {
        return {
            camera: null,
            deviceId: null,
            img: null,
            devices: [],
            cropped: null,
            startCam: true
        };
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    methods: {
        ...mapMutations("creditOperation/customer", ["SET_PHOTO_BLOB"]),
        onCapture() {
            this.img = this.$refs.webcam.capture();
            this.$refs.croppieRef.bind({
                url: this.img
            });
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
            this.startCam = false;
        },
        onStart() {
            this.$refs.webcam.start();
            this.startCam = true;
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            // console.log('On Camera Change Event', deviceId)
        },
        notSupport(message) {
            console.log(message);
        },

        // crop

        // CALBACK USAGE
        crop() {
            let options = {
                format: "jpeg",
                circle: false
            };
            this.$refs.croppieRef.result(options, output => {
                this.cropped = output;
                if (output) {
                    this.SET_PHOTO_BLOB(this.covertStringBlob(output));
                    this.$emit("close");
                }
            });
        },
        result(output) {
            this.cropped = output;
        },
        update(val) {
            console.log(val);
        },
        rotate(rotationAngle) {
            this.$refs.croppieRef.rotate(rotationAngle);
        },
        covertStringBlob(base64String) {
            var block = base64String.split(";");
            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            return this.b64toBlob(realData, contentType);
        },
        b64toBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || "";
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (
                var offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        }
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    }
};
</script>

<style lang="css" scoped></style>
