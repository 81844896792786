var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customer.nameKh")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                            _vm.model.customer_name_kh
                                        ),expression:"\n                                            model.customer_name_kh\n                                        ",modifiers:{"trim":true}}],ref:"customer_name_kh",staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'customer_name_kh'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(
                                            _vm.model.customer_name_kh
                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "customer_name_kh", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                            _vm.errors.has('customer_name_kh')
                                        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("customer_name_kh"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customer.nameEn")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                            _vm.model.customer_name_en
                                        ),expression:"\n                                            model.customer_name_en\n                                        ",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.has(
                                                    'customer_name_en'
                                                ) ||
                                                _vm.$v.model.customer_name_en
                                                    .$error
                                        },attrs:{"type":"text"},domProps:{"value":(
                                            _vm.model.customer_name_en
                                        )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "customer_name_en", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                            _vm.customerNameEnErrors.length > 0
                                        )?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.customerNameEnErrors[0]))]):_vm._e(),(
                                            _vm.errors.has('customer_name_en')
                                        )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("customer_name_en"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(" "+_vm._s(_vm.$t("customer.sex"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"form-control-plaintext"},[_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.gender),expression:"model.gender"}],staticClass:"form-check-input",class:{
                                                    'is-invalid':
                                                        _vm.errors.has(
                                                            'gender'
                                                        ) ||
                                                        _vm.$v.model.gender
                                                            .$error
                                                },attrs:{"type":"radio","id":"male","value":"Male"},domProps:{"checked":_vm._q(_vm.model.gender,"Male")},on:{"input":function($event){return _vm.$v.model.gender.$touch()},"change":function($event){return _vm.$set(_vm.model, "gender", "Male")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"male"}},[_vm._v("Male")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.gender),expression:"model.gender"}],staticClass:"form-check-input",class:{
                                                    'is-invalid':
                                                        _vm.errors.has(
                                                            'gender'
                                                        ) ||
                                                        _vm.$v.model.gender
                                                            .$error
                                                },attrs:{"type":"radio","id":"female","value":"Female"},domProps:{"checked":_vm._q(_vm.model.gender,"Female")},on:{"input":function($event){return _vm.$v.model.gender.$touch()},"change":function($event){return _vm.$set(_vm.model, "gender", "Female")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"female"}},[_vm._v("Female")])]),_c('br'),(_vm.genderErrors.length > 0)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.genderErrors[0]))]):_vm._e(),(_vm.errors.has('gender'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("gender"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customer.dateOfBirth")))]),_c('div',{staticClass:"col-md-8"},[_c('DatePicker',{class:{
                                            'is-invalid':
                                                _vm.errors.has(
                                                    'date_of_birth'
                                                ) ||
                                                _vm.$v.model.date_of_birth
                                                    .$error
                                        },staticStyle:{"width":"100%"},attrs:{"type":"date","value":_vm.model.date_of_birth,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeDateOfBirth}}),(_vm.dateOfBirthErrors.length > 0)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.dateOfBirthErrors[0]))]):_vm._e(),(_vm.errors.has('date_of_birth'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("date_of_birth"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.marritalStatus"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(
                                            _vm.model.marrital_status
                                        ),expression:"\n                                            model.marrital_status\n                                        ",modifiers:{"number":true}}],staticClass:"form-select",class:{
                                            'is-invalid': _vm.errors.has(
                                                'marrital_status'
                                            )
                                        },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "marrital_status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.marritalStatus),function(value,index){return _c('option',{key:index,domProps:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])})],2),(_vm.errors.has('marrital_status'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("marrital_status"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.occupation"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('Select',{class:{
                                            'ivu-form-item-error': _vm.errors.has(
                                                'occupation'
                                            )
                                        },attrs:{"filterable":"","clearable":""},model:{value:(_vm.model.occupation),callback:function ($$v) {_vm.$set(_vm.model, "occupation", $$v)},expression:"model.occupation"}},_vm._l((_vm.occupations),function(sector,index){return _c('OptionGroup',{key:index,attrs:{"label":sector.sector_code +
                                                    '|' +
                                                    (_vm.locale == 'kh'
                                                        ? sector.sector_kh
                                                        : sector.sector_en)}},_vm._l((sector.occupations),function(option,i){return _c('Option',{key:i,attrs:{"value":option.id}},[_vm._v(_vm._s(option.occupation_code + "-" + (_vm.locale == "kh" ? option.occupation_kh : option.occupation_en)))])}),1)}),1),(_vm.errors.has('occupation'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("occupation"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customer.contactNo")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.contact_no),expression:"model.contact_no",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                            'is-invalid':
                                                _vm.errors.has('contact_no') ||
                                                _vm.$v.model.contact_no.$error
                                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.contact_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "contact_no", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.contactNoErrors.length > 0)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.contactNoErrors[0]))]):_vm._e(),(_vm.errors.has('contact_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("contact_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customer.email")))]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.email),expression:"model.email",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'email'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('email'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("email"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.province"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.province_code),expression:"model.province_code"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'province_code'
                                            )
                                        },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "province_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.provinceChange(
                                                _vm.model.province_code
                                            )}],"input":function($event){_vm.model.district_code = _vm.model.commune_code = _vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.provinces),function(province,index){return _c('option',{key:index,domProps:{"value":province.province_code}},[_vm._v(" "+_vm._s(province.name_en)+" | "+_vm._s(province.name_kh)+" ")])})],2),(_vm.errors.has('province_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("province_code"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.district"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.province_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.district_code),expression:"model.district_code"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.errors.has(
                                                    'district_code'
                                                )
                                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "district_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.districtChange(
                                                    _vm.model.district_code
                                                )}],"input":function($event){_vm.model.commune_code = _vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.districts),function(district,index){return _c('option',{key:index,domProps:{"value":district.district_code}},[_vm._v(" "+_vm._s(district.name_en)+" | "+_vm._s(district.name_kh)+" ")])})],2)]),(_vm.errors.has('district_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("district_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.commune"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.district_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.commune_code),expression:"model.commune_code"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.errors.has(
                                                    'commune_code'
                                                )
                                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "commune_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.communeChange(
                                                    _vm.model.commune_code
                                                )}],"input":function($event){_vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.communes),function(commune,index){return _c('option',{key:index,domProps:{"value":commune.commune_code}},[_vm._v(" "+_vm._s(commune.name_en)+" | "+_vm._s(commune.name_kh)+" ")])})],2)]),(_vm.errors.has('commune_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("commune_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.village"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.commune_change}},[_c('div',{staticClass:"input-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.village_code),expression:"model.village_code"}],staticClass:"form-control",class:{
                                                    'is-invalid': _vm.errors.has(
                                                        'village_code'
                                                    )
                                                },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "village_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.villages),function(village,index){return _c('option',{key:index,domProps:{"value":village.village_code}},[_vm._v(" "+_vm._s(village.name_en)+" | "+_vm._s(village.name_kh)+" ")])})],2),(
                                                    !_vm.villageSaving &&
                                                        _vm.model.commune_code
                                                )?_c('Poptip',{attrs:{"title":_vm.$t('customer.village'),"transfer":true,"width":"240"},on:{"on-popper-show":function () { return (_vm.name_en = _vm.name_kh =
                                                            ''); }}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-info",attrs:{"disabled":_vm.villageSaving}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t( "customer.nameEn" )))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                                                _vm.name_en
                                                            ),expression:"\n                                                                name_en\n                                                            ",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                                'is-invalid':
                                                                    _vm.errors.has(
                                                                        'name_en'
                                                                    ) ||
                                                                    _vm.$v
                                                                        .name_en
                                                                        .$error
                                                            },attrs:{"type":"text"},domProps:{"value":(
                                                                _vm.name_en
                                                            )},on:{"input":function($event){if($event.target.composing){ return; }
                                                                _vm.name_en
                                                            =$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                                                _vm.nameEnErrors.length >
                                                                    0
                                                            )?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.nameEnErrors[0]))]):_vm._e(),(
                                                                _vm.errors.has(
                                                                    'name_en'
                                                                )
                                                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( "name_en" ))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t( "customer.nameKh" )))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(
                                                                _vm.name_kh
                                                            ),expression:"\n                                                                name_kh\n                                                            ",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                                'is-invalid':
                                                                    _vm.errors.has(
                                                                        'name_kh'
                                                                    ) ||
                                                                    _vm.$v
                                                                        .name_kh
                                                                        .$error
                                                            },attrs:{"type":"text"},domProps:{"value":(
                                                                _vm.name_kh
                                                            )},on:{"input":function($event){if($event.target.composing){ return; }
                                                                _vm.name_kh
                                                            =$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                                                _vm.nameEnErrors.length >
                                                                    0
                                                            )?_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.nameEnErrors[0]))]):_vm._e(),(
                                                                _vm.errors.has(
                                                                    'name_kh'
                                                                )
                                                            )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first( "name_kh" ))+" ")]):_vm._e()]),_c('div',{staticClass:"tw-mt-2 tw-text-right"},[_c('a',{staticClass:"ivu-btn ivu-btn-primary ivu-btn-small",on:{"click":function($event){$event.preventDefault();return _vm.onSaveVillage.apply(null, arguments)}}},[_c('span',[_vm._v("Save")])])])])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-default",attrs:{"disabled":!_vm.model.commune_code}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})])],1)]),(_vm.errors.has('village_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("village_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-4"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.street"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.street_no),expression:"model.street_no"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'street_no'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.street_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "street_no", $event.target.value)}}}),(_vm.errors.has('street_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("street_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-4"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.house"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.house_no),expression:"model.house_no"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'house_no'
                                            )
                                        },attrs:{"type":"text"},domProps:{"value":(_vm.model.house_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "house_no", $event.target.value)}}}),(_vm.errors.has('house_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("house_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("customer.description"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-control",class:{
                                            'is-invalid': _vm.errors.has(
                                                'description'
                                            )
                                        },attrs:{"type":"text","rows":"3"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "description", $event.target.value)}}}),(_vm.errors.has('description'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("description"))+" ")]):_vm._e()])])])])]),_c('div',{staticClass:"col-md-4"},[_c('fieldset',[_c('legend',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t("customer.ssn"))+" ")]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.documentType")))]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.doc_type_id),expression:"model.doc_type_id",modifiers:{"number":true}}],staticClass:"form-select",class:{
                                        'is-invalid': _vm.errors.has(
                                            'doc_type_id'
                                        )
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "doc_type_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.documentTypes),function(doc_type,index){return _c('option',{key:index,domProps:{"value":doc_type.doc_type_id}},[_vm._v(" "+_vm._s(doc_type.doc_type_en)+" | "+_vm._s(doc_type.doc_type_kh)+" ")])})],2),(_vm.errors.has('doc_type_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("doc_type_id"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassport")))]),_c('div',{staticClass:"col-md-7"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.ssn_no),expression:"model.ssn_no",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has('ssn_no')
                                    },attrs:{"type":"text"},domProps:{"value":(_vm.model.ssn_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "ssn_no", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('ssn_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("ssn_no"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassportIssuedDate")))]),_c('div',{staticClass:"col-md-7"},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value":_vm.model.issued_date,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeIssuedDate}}),(_vm.errors.has('issued_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("issued_date"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassportExpiryDate")))]),_c('div',{staticClass:"col-md-7"},[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value":_vm.model.expiry_date,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeExpiryDate}}),(_vm.errors.has('expiry_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("expiry_date"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-2"},[_c('label',{staticClass:"form-label col-form-label col-md-5"},[_vm._v(_vm._s(_vm.$t("customer.nidOrpassportIssuedAt")))]),_c('div',{staticClass:"col-md-7"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.issue_at),expression:"model.issue_at",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has('issue_at')
                                    },attrs:{"rows":"7"},domProps:{"value":(_vm.model.issue_at)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "issue_at", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('issue_at'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("issue_at"))+" ")]):_vm._e()])])])])])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('ts-image-input',{staticClass:"image-input",attrs:{"file":_vm.file,"width":175,"height":225,"crop":""},on:{"input":function (file) { return (_vm.model.photo = file); }}}),_c('ts-button',{staticClass:"tw-mt-2",on:{"click":function () { return (_vm.showForm = true); }}},[_c('i',{staticClass:"fas fa-camera mr-2"}),_vm._v(" "+_vm._s(_vm.$t("customer.takePhoto"))+" ")]),_c('div',{staticStyle:{"color":"#cc4946","margin":"4px 0 0"}},[_vm._v(" "+_vm._s(_vm.errors.first("photo"))+" ")])],1)])]),(_vm.showForm)?_c('div',[_c('Modal',{attrs:{"title":_vm.$t('customer.takePhoto'),"draggable":"","sticky":"","scrollable":"","footer-hide":true,"width":"690"},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('web-cam',{on:{"close":_vm.getPhoto}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }